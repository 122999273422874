  <template>
  <div class="table w-full mb-20">
    <modal name="details" />
    <div class="w-full">
      <div class="w-full flex justify-end">
        <div v-if="$route.name == 'Faq' || $route.name == 'CustomSearch'" class="m-2 mx-5 flex">
            <input
              class="border-b border-black outline-none mr-10 px-1"
              :class="{error: errors.category}"
              placeholder="Insert category"
              v-model="category" type="text"
            >
          <!-- </div> -->
          <span
          @click="addCategorytoFaq"
          class="rounded-lg bg-green-400 hover:bg-green-500 p-2 pr-3 cursor-pointer text-white"
          >
          <span class="font-bold">+</span>
          Add Category
          </span>
        </div>
        <span
          v-else
          @click="showModal(true, keys, {}, 'add')"
          class="rounded-lg bg-green-400 hover:bg-green-500 p-2 cursor-pointer my-2 mx-5 text-white"
        >
          <span class="font-bold">+</span>
          Add Data
        </span>
        <span
          @click="deleteSelected"
          class="rounded-lg bg-red-500 hover:bg-red-600 p-2 cursor-pointer m-2 text-white"
          >Delete Selected</span
        >
      </div>
      <div class="w-full bg-gray-800 text-white rounded-t-lg">
        <!-- head -->
        <div class="w-full flex">
          <div class="table-start py-6 "></div>
          <!-- column names -->
          <div
            class="table-cell font-bold"
            v-for="k in columnNamesComputed.length"
            :key="k"
          >
            {{ _.capitalize(columnNamesComputed[k - 1]) }}
          </div>
          <div class="table-end"></div>
        </div>
      </div>
      <draggable class="border border-gray-800" v-model="data" @change="dragged">
        <!-- ROW -->
        <div class="row flex w-full" v-for="(i, n) in data" :key="n">
          <table-section
            v-if="i.isString"
            :item="i"
            :requests="requests"
            @delete="showDeleteModal(i, true)"
            @add="showModal(true, keys, {id:i._id}, 'add')"
            @getData="$emit('getData')"
          />
          <div class="flex w-full" v-else>
          <!-- Checkmarks -->
            <div class="table-start flex justify-around items-center">
              <label :for="`check-${n}`" class="checkboxContainer font-bold">
                <input
                  type="checkbox"
                  :id="`check-${n}`"
                  v-model="selectedItems"
                  :value="i._id"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <!-- Cells -->
            <div
              class="table-cell my-2"
              v-for="k in columnNamesComputed.length"
              :key="k"
            >
              <!-- if image or logo use img tag -->
              <img
                class="w-16 h-16 my-1 border border-gray-300 rounded-md"
                v-if="
                  (columnNamesComputed[k - 1] == 'logo' ||
                  columnNamesComputed[k - 1] == 'icon' ||
                  columnNamesComputed[k - 1] == 'banner' ||
                  columnNamesComputed[k - 1] == 'image') &&
                  i[ columnNamesComputed[k - 1]]
                "
                :src="`${dbLink}/${i[columnNamesComputed[k - 1]]}`"
                alt="image"
              />
              <!-- if array -->
              <array-cell
                v-else-if="
                  columnNamesComputed[k - 1] == 'subLinks' ||
                  columnNamesComputed[k - 1] == 'links' ||
                  columnNamesComputed[k - 1] == 'categories' ||
                  columnNamesComputed[k - 1] == 'emails'
                "
                :type="columnNamesComputed[k - 1]"
                :array="i[columnNamesComputed[k - 1]]"
              />
              <!-- if subareas array -->
              <array-cell
                v-else-if="
                  columnNamesComputed[k - 1] == 'area' &&
                  i['subAreas'] &&
                  i['subAreas'].length
                "
                :type="columnNamesComputed[k - 1]"
                :array="[i.area, ...i.subAreas]"
              />
              <!-- else print text -->
              <span
                class="w-full text-center"
                v-html="shortenText(i[columnNamesComputed[k - 1]])"
                v-else
              />
            </div>
            <!-- buttons -->
            <div class="table-end">
              <div class="flex">
                <!-- inspect -->
                <span
                  @click="showModal(false, Object.keys(i), i, 'inspect')"
                  class="bg-green-500 hover:bg-green-600 cm-button"
                >
                  <font-awesome-icon icon="eye" />
                </span>
                <!-- Edit -->
                <span
                  @click="showModal(true, keys, i, 'edit')"
                  class="bg-blue-500 hover:bg-blue-600 cm-button"
                >
                  <font-awesome-icon icon="pen" />
                </span>
                <!-- delete -->
                <span
                  @click="showDeleteModal(i)"
                  class="bg-red-500 hover:bg-red-600 cm-button"
                >
                  <font-awesome-icon icon="trash" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import detailView from '@/components/DetailView.vue'
import deleteModal from '@/components/DeleteModal.vue'
import ArrayCell from '@/components/tableComponents/ArrayCell.vue'
import TableSection from '@/components/tableComponents/TableSection.vue'

export default {
  name: 'Table',
  components: {
    draggable,
    ArrayCell,
    TableSection
  },
  props: {
    lang: String,
    columnNames: Array,
    keys: Array,
    requests: Object
  },
  data () {
    return {
      selectedItems: [],
      dbLink: process.env.VUE_APP_API_URL,
      category: '',
      errors: {},
      isEditing: false
    }
  },
  methods: {
    async deleteCategory (id) {
      await this.requests.deleteCategory(id)
      this.$emit('getData')
    },
    async addCategorytoFaq () {
      if (this.category && this.category.trim()) {
        try {
          await this.requests.addCategory(this.lang, this.category)
          this.$emit('getData')
          this.category = ''
        } catch (error) {
          console.log(error)
        }
        delete this.errors.category
      } else {
        this.errors.category = true
        this.category = ''
      }
      this.$forceUpdate()
    },
    // shotrens longer texts on table
    shortenText (text) {
      const limit = 50
      if (text && text.length > limit) {
        return text.substring(0, limit) + '...'
      }
      return text
    },
    // reorders elements when dragged
    async dragged (e) {
      try {
        await this.requests.changeOrder(this.data, e.moved.oldIndex)
      } catch (error) {
        console.log(error)
      }
      this.$emit('getData')
    },
    // if there are selected items send request to delete selected items
    async deleteSelected () {
      if (this.selectedItems.length) {
        try {
          await this.requests.deleteMany(this.selectedItems).then((res) =>
            // if succesful refresh front
            this.$emit('getData')
          )
          this.selectedItems = []
        } catch (error) {
          console.log(error)
        }
      }
    },
    // when modal closes
    onCloseModal () {
      // reset changed data if not submitted
      if (this.$store.state.modalState.type === 'edit' && !this.$store.state.modalState.changed) { this.resetUnchangedData() }
      // if changed refresh frontend
      if (this.$store.state.modalState.changed) {
        this.$emit('getData')
        this.$store.commit('setChanged', false)
      }
    },
    resetUnchangedData () {
      // reset data to previous state if edit is not submitted
      for (const k of Object.keys(this.data.find((x) => x._id === this.$store.state.modalState.prevItem._id))) {
        this.data.find((x) => x._id === this.$store.state.modalState.prevItem._id)[k] = this.$store.state.modalState.prevItem[k]
      }
    },
    showDeleteModal (item, isCategory = false) {
      // sets delete dialoge
      this.$store.commit('setType', 'delete')
      this.$store.commit('setCurrentItem', item)
      this.$modal.show(
        deleteModal,
        {
          item,
          requests: this.requests,
          isCategory
        },
        {
          height: 200,
          width: 400
        },
        {
          closed: this.onCloseModal
        }
      )
    },
    showModal (isEditable, keys, item, type) {
      // set modal variables and open modal
      this.$store.commit('setPrevItem', _.cloneDeep(item))
      this.$store.commit('setType', type)
      this.$store.commit('setCurrentItem', item)
      this.$modal.show(
        detailView,
        {
          isEditable,
          keys,
          item,
          lang: this.lang,
          requests: this.requests
        },
        { height: 'auto', scrollable: true },
        { closed: this.onCloseModal }
      )
    }
  },
  computed: {
    columnNamesComputed: function () {
      return this.columnNames
        ? this.columnNames
        : Object.keys(this.data[0]).slice(0, 5)
    },
    data: {
      get: function () {
        return this.$store.state.data
      },
      set: function (data) {
        this.$store.commit('setData', data)
      }
    },
    _ () {
      return _
    }
  }
}
</script>

<style scoped lang="scss">
.row:nth-child(even){
    background-color: rgba(31, 41, 55, 0.05);
}
</style>
